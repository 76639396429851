import React from "react";
import Placeholder from "../../../../core/Components/Placeholder";
import {
  calculateSpacing,
  getBackgroundPosition,
  getBackgroundSize,
  getHorizontalAlign,
  getVerticalAlign,
  joinClasses,
} from "../../../../utils/getContainerClasses";
import useIsMobile from "../../../../utils/useIsMobile";
import GenericImage from "../../../content/_generic/GenericImage/GenericImage";
import buildPatternBackground from "../buildPatternBackground";

const TwoColumnContainer = ({ fields, rendering }) => {
  const isMobile = useIsMobile();

  const MobileExternalBackground = () => (
    <div
      className={`mobile-external-background ${
        position === "before" ? "mb-4" : "mt-4"
      }`}
    >
      <GenericImage
        fields={{
          image: fields.backgroundImage,
          roundedCorners: fields.roundedCorners,
        }}
      />
    </div>
  );

  const patternBackgroundString = buildPatternBackground({
    isMobile,
    patternBackgroundLeft: fields.patternBackgroundLeft,
    patternBackgroundRight: fields.patternBackgroundRight,
  });

  return (
    <section
      id={fields.id?.value}
      className={joinClasses(
        "column-container",
        fields.backgroundColor?.name && `bg-${fields.backgroundColor.name}`,
        getBackgroundSize(fields.backgroundSize?.name),
        getBackgroundPosition(fields.backgroundPosition?.name),
        fields.backgroundMobileExternalPosition?.name &&
          "has-mobile-external-background",
        (fields.backgroundImageRtlFlip?.value || patternBackgroundString) &&
          "background-image-rtl-flip",
        fields.roundedCorners?.value && "rounded",
        fields.outerClassName?.value,
        calculateSpacing(fields),
      )}
      style={
        patternBackgroundString
          ? { background: patternBackgroundString }
          : fields.backgroundImage?.value?.src
            ? { backgroundImage: `url('${fields.backgroundImage.value.src}')` }
            : undefined
      }
    >
      <div className={fields.useContainer?.value ? "container" : undefined}>
        <div
          className={joinClasses(
            "row",
            fields.useContainer?.value && "gy-6 gy-md-0",
            fields.rowReverse?.value && "flex-md-row-reverse",
            getHorizontalAlign(fields.horizontalAlign?.name),
            getVerticalAlign(fields.verticalAlign?.name),
          )}
        >
          {fields.backgroundMobileExternalPosition?.name === "before" &&
          fields.backgroundImage?.value?.src ? (
            <MobileExternalBackground position="before" />
          ) : null}

          <div className={`col-md-${fields.col1?.value}`}>
            <Placeholder name="two-column-content-1" rendering={rendering} />
          </div>
          <div className={`col-md-${fields.col2?.value}`}>
            <Placeholder name="two-column-content-2" rendering={rendering} />
          </div>

          {fields.backgroundMobileExternalPosition?.name === "after" &&
          fields.backgroundImage?.value?.src ? (
            <MobileExternalBackground position="after" />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default TwoColumnContainer;
