const patternBackgroundConfig = {
  // cochlear kids page
  // light purple with dots
  "cochlear-kids-pattern-1-desktop": "right -100px top -100px/400px",
  "cochlear-kids-pattern-1-mobile": "right -125px top -125px/250px",
  // yellow with dots
  "cochlear-kids-pattern-2-desktop": "-100px 150px/400px",
  "cochlear-kids-pattern-2-mobile": "-150px 150px/250px",
  // dark purple with curved lines
  "cochlear-kids-pattern-3-desktop": "right 0px top -200px/400px",
  "cochlear-kids-pattern-3-mobile": "right -125px top -100px/250px",
  // yellow with round shapes
  "cochlear-kids-pattern-4-desktop": "left -125px bottom -75px/400px",
  "cochlear-kids-pattern-4-mobile": "left -125px bottom -75px/250px",
  // light purple with straightish lines
  "cochlear-kids-pattern-5-desktop": "right -200px top 0px/400px",
  "cochlear-kids-pattern-5-mobile": "right -125px top -100px/250px",
  // yellow with dots shapes
  "cochlear-kids-pattern-6-desktop": "right -125px bottom -225px/400px",
  "cochlear-kids-pattern-6-mobile": "right -125px bottom -75px/250px",

  // professional system page
  // multi circle dots
  "multi-circle-dots-desktop": "right -25px top 100px/500px",
  "multi-circle-dots-mobile": "top -150px center/150%",
  // multi circle stripes
  "multi-circle-stripes-desktop": "left -200px top -100px/800px",
  "multi-circle-stripes-mobile": "top -400px center/250%",
  // brand-wave
  "brand-wave-desktop": "top right/300px",
  "brand-wave-mobile": "top right/90%",
  // single circle dots
  "single-circle-dots-desktop": "left -100px bottom -300px/600px",
  "single-circle-dots-mobile": "left -200px bottom -300px/500px",

  // professional sound processor, implant pages
  // inverse curve bottom
  "inverse-curve-bottom-desktop": "bottom right/450px",
  "inverse-curve-bottom-mobile": "bottom right/90%",
};

/**
 * Builds a string of one or more pattern backgrounds to use as a background style property
 * @param {*} param0
 */
const buildPatternBackground = ({
  isMobile,
  patternBackgroundLeft,
  patternBackgroundRight,
}) => {
  let patternBackground = "";

  if (patternBackgroundLeft) {
    patternBackground += `url('${
      patternBackgroundLeft.fields.image.value.src
    }') ${
      patternBackgroundConfig[
        `${patternBackgroundLeft.name}-${isMobile ? "mobile" : "desktop"}`
      ]
    } no-repeat`;
  }

  if (patternBackgroundRight) {
    if (patternBackground) {
      patternBackground += ", ";
    }

    patternBackground += `url('${
      patternBackgroundRight.fields.image.value.src
    }') ${
      patternBackgroundConfig[
        `${patternBackgroundRight.name}-${isMobile ? "mobile" : "desktop"}`
      ]
    } no-repeat`;
  }

  return patternBackground;
};

export default buildPatternBackground;
