import { useEffect, useState } from "react";

const DEFAULT_MOBILE_MAX_WIDTH = 767;

const useIsMobile = (mobileMaxWidth = DEFAULT_MOBILE_MAX_WIDTH) => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${mobileMaxWidth}px)`);
    const update = () => {
      setIsMobile(mql.matches);
    };

    mql.addEventListener("change", update);
    update();

    return () => {
      mql.removeEventListener("change", update);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
